:root {
  /* 
    Letter size (landscape): 11 x 8.5 in
    Margin: 0.5 in on all sides
    Printable area: 10 x 7.5 in
    Page: 5 x 7.5 in
  */
  --pageHeader: 80px;
  --pageFooter: 40px;
  --pageMargin: 32px;
  --pageWidth: calc(100vw - calc(2 * var(--pageMargin)));
  --pageHeight: calc(100vh - var(--pageHeader) - var(--pageMargin));
  --unit: min(calc(var(--pageWidth) / 50), calc(var(--pageHeight) / 75));

  --fontSize: min(48px, 6vw);

  --c1: #fc00ff;
  --c2: #5500ff;
  --c3: #00c2ff;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

#root,
button {
  font-family: 'Urbanist';
  font-kerning: normal;
}
