.support-section {
  border: 2px solid var(--c2);
  border-radius: 16px;
  margin-bottom: 32px;
  overflow: hidden;
}

.support-title {
  display: block;
  margin: 0;
  padding: 16px;
  background-color: var(--c2);
  color: #fff;
}

.accordion {
  padding: 16px;
  cursor: pointer;
  transition: all 200ms ease;
}

.accordion a {
  color: var(--c2);
}

.accordion:not(:last-child) {
  border-bottom: 2px solid var(--c2);
}

.accordion-title {
  display: flex;
}

.accordion-title::before {
  display: inline-block;
  width: 20px;
  content: '+';
  flex-shrink: 0;
}

.accordion:hover,
.accordion.open {
  background: var(--c1);
  color: #fff;
}

.accordion.open .accordion-title::before {
  content: '-';
}

.accordion-content {
  padding: 16px 0 8px 20px;
}
