.book {
  position: relative;
  box-sizing: border-box;
  width: calc(50 * var(--unit));
  height: calc(75 * var(--unit));
  padding: calc(5 * var(--unit));
  background-size: 100% 100%;
  font-size: calc(1.7 * var(--unit));
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
}

.book-print-surface {
  position: fixed;
  top: 0;
  left: 200vw;
}

.book-print-double {
  display: flex;
  width: calc(100 * var(--unit));
  height: calc(75 * var(--unit));
  flex-shrink: 0;
}

.book.book-print,
.book-print-double .book {
  border-radius: 0;
  margin: 0;
}

.book-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.book.right .book-background {
  transform: scaleX(-1);
}

.book-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: calc(9 * var(--unit)) calc(5 * var(--unit));
  position: relative;
}

.front-cover .book-line:nth-child(1) {
  font-weight: var(--fontWeightBold);
  font-size: calc(5 * var(--unit));
}

.front-cover .book-line:nth-child(2) {
  text-transform: uppercase;
  position: absolute;
  bottom: calc(8 * var(--unit));
  font-size: calc(1.2 * var(--unit));
  font-weight: var(--fontWeightBold);
}

.front-cover .book-line:nth-child(3) {
  text-transform: uppercase;
  position: absolute;
  bottom: calc(5 * var(--unit));
  font-size: calc(1.4 * var(--unit));
  font-weight: var(--fontWeightBold);
}

.back-cover .cover-logo {
  width: calc(7 * var(--unit));
}

.back-cover .book-line {
  font-family: 'Unica One';
  font-size: calc(3 * var(--unit));
  color: var(--c2);
}

.book-line {
  margin: calc(1.5 * var(--unit)) 0;
}

.book-number {
  position: absolute;
  bottom: calc(4 * var(--unit));
}

.book-button {
  box-sizing: border-box;
  position: absolute;
  text-align: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  top: calc(50% - 23px);
  font-size: 30px;
  padding: 0 0 3px 0;
  background: #fff;
  color: var(--c2);
  border: 3px solid var(--c2);
}

.book-button:hover {
  background: var(--c2);
  color: #fff;
}

.book-button.left {
  left: 8px;
}

.book-button.right {
  right: 8px;
}
