.input {
  transition: border 200ms ease;
  border-bottom: 3px solid #aaa;
  cursor: text;
  font-weight: 700;
  color: var(--c1);
}

.input:focus {
  outline: none;
}

.input:focus,
.input:not(.empty) {
  border-color: var(--c1);
}

.input.empty:after {
  color: #aaa;
  font-weight: 100;
  content: attr(placeholder);
}

.input.empty.animated:after {
  background: linear-gradient(to right, #aaa 0%, #aaa 50%, #fff 51%, #fff 100%);
  background-size: 200% auto;
  background-position: 100% center;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: appear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes appear {
  to {
    background-position: 0% center;
  }
}
