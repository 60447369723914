.app-root {
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 var(--pageMargin);
  display: flex;
  flex-direction: column;
}

.app-header {
  flex: 0 0 var(--pageHeader);
  display: flex;
  align-items: center;
}

.app-header-brand {
  display: flex;
  align-items: center;
  user-select: none;
}

.app-header-logo {
  height: 44px;
  margin-right: 8px;
}

.app-header-brand > div {
  font-family: 'Unica One';
  font-size: 28px;
  background: linear-gradient(90deg, var(--c1) 0%, var(--c2) 50%, var(--c3) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.app-content {
  flex: 1;
  padding: var(--pageMargin) 0;
}

.app-footer {
  flex: 0 0 var(--pageFooter);
  display: flex;
  justify-content: center;
  align-items: center;
}

.prompt-card {
  display: flex;
  border-radius: 32px;
  margin-bottom: 64px;
  border: 5px solid var(--c2);
}

.prompt-title {
  writing-mode: tb-rl;
  transform: scale(-1);
  text-align: center;
  background: var(--c2);
  border-radius: 0 26px 26px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 0;
  margin: -1px;
}

.prompt-content {
  padding: 32px;
}

.prompt {
  font-size: var(--fontSize);
  font-weight: 400;
  line-height: 180%;
  user-select: none;
}

.prompt-bold {
  font-weight: 700;
}

.prompt-styles {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.modal .prompt-styles {
  padding: 32px 0;
}

.prompt-styles div {
  flex: 0 1 120px;
  cursor: pointer;
  transition: background 200ms ease;
  padding: min(10px, 1vw);
  border-radius: 50%;
  display: flex;
  margin: 0 5px;
}

.prompt-styles div:hover {
  background: #ccc;
}

.prompt-styles div.selected {
  background: linear-gradient(to right, var(--c1) 0%, var(--c2) 51%, var(--c1) 100%);
}

.prompt-styles div img {
  box-sizing: border-box;
  width: 100%;
  border-radius: 50%;
  border: min(5px, 1vw) solid #fff;
}

.prompt-buttons {
  display: flex;
  justify-content: center;
  margin: 32px 0 64px;
}

.button {
  background: none;
  border: none;
  cursor: pointer;
  transition: all 200ms ease;
  font-size: 16px;
  border-radius: 12px;
}

.button.primary {
  background-image: linear-gradient(to right, var(--c1) 0%, var(--c2) 51%, var(--c1) 100%);
  background-size: 200% auto;
  color: #fff;
  border: 2px solid transparent;
  padding: 10px 16px;
}

.button.primary.blue {
  background-image: linear-gradient(to right, var(--c2) 0%, var(--c3) 51%, var(--c2) 100%);
}

.button.icon {
  display: inline-flex;
  align-items: center;
}

.button.icon svg {
  transform: scale(1.4);
  margin-right: 8px;
}

.button.primary:hover {
  background-position: right center;
}

.button.secondary {
  color: var(--c2);
  border: 2px solid var(--c2);
  padding: 10px 16px;
}

.button.secondary:hover {
  color: #fff;
  background: var(--c2);
}

.button.secondary.white {
  color: #fff;
  border-color: #fff;
}

.button.secondary.white:hover {
  color: #000;
  background: #fff;
}

.button.large {
  border-radius: 16px;
  padding: 20px 32px;
  font-size: 24px;
}

.button.small {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
  padding: 0px !important;
}

.button.link {
  color: #000;
  padding: 0 0 4px;
  border-bottom: 1px solid #fff;
  font-size: 14px;
  border-radius: unset;
}

.button.link:hover {
  border-color: #000;
}

.button + .button {
  margin-left: 8px;
}

.button.link + .button.link {
  margin-left: 32px;
}

.modal {
  position: fixed;
  inset: 0;
  padding: 0 var(--pageMargin) var(--pageMargin);
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
}

.modal-header {
  max-width: 1130px;
  width: 100%;
  margin: 0 auto;
  flex: 0 0 var(--pageHeader);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  flex-direction: column;
  color: #fff;
  font-size: 32px;
  text-align: center;
}

.loading-text-small {
  font-size: 16px;
  margin: 16px 0;
}

.loading-bar {
  height: 8px;
  border-radius: 4px;
  background-color: var(--c1);
  width: min(100%, 500px);
  overflow: hidden;
  margin-top: 32px;
}

.loading-bar-value {
  width: 100%;
  height: 100%;
  background-color: var(--c2);
  animation: loading 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes loading {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

@media (max-width: 720px) {
  .prompt-card {
    flex-direction: column;
    margin-bottom: 32px;
  }

  .prompt-content {
    padding: 16px;
  }

  .prompt-styles div {
    margin: 0;
  }

  .prompt-title {
    writing-mode: unset;
    transform: none;
    border-radius: 26px 26px 0 0;
  }
}

@media (min-width: 721px) {
  .button.primary:not(.large),
  .button.secondary:not(.large) {
    padding: 14px 20px;
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .mobile {
    display: block;
  }

  .not-mobile {
    display: none;
  }

  .app-header-logo {
    height: 30px;
    margin-right: 6px;
  }

  .app-header-brand > div {
    font-size: 18px;
  }
}

@media (min-width: 481px) {
  .mobile {
    display: none;
  }

  .not-mobile {
    display: block;
  }
}

.legal-page {
  flex: 1;
  width: calc(min(100%, 1130px) - 64px);
  align-self: center;
  padding: 32px;
  border-radius: 32px;
  overflow-y: auto;
  font-size: 18px;
  background: #fff;
}

.legal-page a {
  color: var(--c2);
}

.preview-body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  max-width: 1130px;
  margin: 0 auto;
  gap: 32px;
}

.preview-prompt {
  flex: 1;
  color: #fff;
  font-size: min(32px, var(--fontSize));
  min-width: min(500px, calc(100vw - 64px));
}

.error-container {
  padding: 48px 36px 36px;
  max-width: 500px;
  background: linear-gradient(84.81deg, var(--c1) 0%, var(--c2) 53.13%, var(--c3) 100%);
  color: #fff;
  font-size: 16px;
  border-radius: 32px;
  position: relative;
}

.error-container a {
  text-decoration: underline;
  cursor: pointer;
}

.error-container input {
  width: 100%;
  box-sizing: border-box;
  display: block;
  border: none;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 12px;
}

.error-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 16px;
}

.error-close {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-close > svg {
  width: 20px;
  height: 20px;
}
